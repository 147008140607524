<template>
  <div>
    <div v-if="status">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 10000"
        @click.stop="hide"
      ></div>
      <div class="el-add-company">
        <div class="space-between">
          <div class="text-main font-lighter">添加屏蔽公司</div>
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <div class="company-box">
          <div class="add-input">
            <el-input placeholder="请输入内容" v-model="title" class="input-with-select">
              <el-button
                slot="append"
                type="primary"
                icon="el-icon-search"
                @click="searchClick"
              ></el-button>
            </el-input>
          </div>
          <div class="add-company-text" v-if="searchShow == false">
            <div>可以通过以下方式搜索公司</div>
            <div>公司全称：如“杭州精纱信息技术有限公司”</div>
            <div>公司全称：如“中国纱线网”</div>
          </div>
          <div class="el-add-company-box" v-else>
            <div class="el-div-check-box-group">
              <el-checkbox-group
                v-model="checkedCities"
                @change="handleCheckedCitiesChange"
              >
                <div
                  v-for="city in cities"
                  :key="city.id"
                  class="el-div-check-box column"
                >
                  <div>
                    <el-checkbox :label="city.id">{{ city.ent_name }}</el-checkbox>
                  </div>
                  <div class="text-blue box-text">简称“{{ city.short_name }}”</div>
                </div>
              </el-checkbox-group>
            </div>
            <div class="space-between el-add-company-box-bottom">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >所有与 {{ title }} 相关的公司</el-checkbox
              >
              <div
                class="text-white bg-blue flex-center font-normal el-bottom-button pointer"
                @click="buttonClick"
              >
                屏蔽所选公司
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../api/user";
export default {
  components: {},
  
  data() {
    return {
      status: false,
      title: "",
      searchShow: false,
      checkAll: false,
      checkedCities: [],
      cities: [],
      isIndeterminate: true,
    };
  },

  created() {
  },

  mounted() {},
watch:{
 
},
  methods: {
    hide() {
      this.status = false;
    },
    show() {
      this.status = true;
    },
    // 多选操作
    handleCheckAllChange(val) {
      if (val) {
        this.checkedCities = this.cities.map((item) => {
          return item.id;
        });
      } else {
        this.checkedCities = [];
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    },
    // 搜索相关事件
    searchClick() {
      if (this.title == "") {
        this.$message({
          message: "请输入搜索内容",
          type: "warning",
          customClass: "global-messageZindex",
        });
        return;
      }
      api.searchList({ title: this.title }).then((res) => {
        if (res.code == 200) {
          
          this.cities =res.data.data;
          this.searchShow = true;
        }
      });
    },
    // 屏蔽所有公司事件
    buttonClick() {
      if (this.checkedCities.length == 0) {
        this.$message({
          message: "请选择屏蔽的公司",
          type: "warning",
          customClass: "global-messageZindex",
        });
        return;
      }
      this.$emit("buttonClick", this.checkedCities);
      this.hide();
      this.title = "";
      this.checkAll = false;
      this.searchShow = false;
      this.cities = [];
      this.checkedCities = [];
    },
  },
};
</script>
<style>
.global-messageZindex {
  z-index: 30000 !important;
}
</style>
<style lang="less" scoped>
.el-add-company {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 510px;
  height: 478px;
  background: #ffffff;
  z-index: 10001;
  padding: 1.25rem 1.25rem 1.25rem 1.8125rem;
  padding: 20px 20px 20px 29px;
  .company-box {
    width: 100%;
    padding-right: 10px;
    margin-top: 1.5625rem;
    .add-company-text {
      color: #8d92a1;
      font-size: 12px;
      margin-top: 1.4375rem;
      div:first-child {
        color: #51586d;
        font-size: 13px;
        margin-bottom: 1.25rem;
      }
    }
    .el-add-company-box {
      .el-div-check-box-group {
        width: 100%;
        height: 300px;
        overflow-y: hidden;
      }
      .el-div-check-box-group::-webkit-scrollbar {
        display: none;
      }
      .el-div-check-box {
        width: 100%;
        height: 75px;
        justify-content: center;
        border-bottom: 1px solid #eef0f5;
        .box-text {
          font-size: 13px;
          margin-top: 10px;
          padding-left: 1.5rem;
          color: #409eff;
        }
      }
      .el-add-company-box-bottom {
        height: 75px;
        .el-bottom-button {
          width: 116px;
          height: 34px;
        }
      }
    }
  }
}
</style>
